/* src/Dashboard.css */

.dashboard-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f8f9fa;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #ffffff;
  border-bottom: 1px solid #dee2e6;
}

.header-logo {
  height: 50px; /* Ajusta el tamaño según prefieras */
}

.header-logo2 {
  height: 70px; /* Ajusta el tamaño según prefieras */
}

.search-container {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.search-input {
  width: 200px; /* Ancho fijo */
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-left: 20px; /* Espacio entre el logo y el buscador */
}

.filter-select {
  min-width: 200px;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 20px; /* Espacio entre el logo2 y el filtro */
}

.dashboard-content {
  flex: 1;
  padding: 20px;
}

.dashboard-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.logout-button {
  padding: 10px 20px;
  background-color: #072A44;
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.logout-button:hover {
  background-color: #072A44;
}

.usuarios-table {
  width: 100%;
  border-collapse: collapse;
}

.usuarios-table th,
.usuarios-table td {
  border: 1px solid #dee2e6;
  padding: 12px;
  text-align: left;
}

.usuarios-table th {
  background-color: #e9ecef;
}

.action-button {
  padding: 8px 16px;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.action-button:hover {
  background-color: #0056b3;
}

.action-buttons {
  display: flex;
  gap: 5px;
  justify-content: center;
  margin-right: 5px;
}

/* Responsividad para dispositivos móviles */
@media (max-width: 768px) {
  .dashboard-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .search-container {
    flex-direction: column;
    justify-content: flex-start; /* Alinea todo al inicio en móvil */
    gap: 10px;
    width: 100%;
  }

  .search-input,
  .filter-select {
    width: 100%; /* Ocupa todo el ancho */
  }

  .logout-button {
    width: 100%;
    text-align: center;
  }
}
