/* src/Login.css */

.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f2f5;
  }
  
  .login-form {
    background-color: #ffffff;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .login-logo {
    width: 100px; /* Ajusta el tamaño según tu preferencia */
    margin-bottom: 20px;
  }
  
  .login-form h2 {
    margin-bottom: 20px;
    text-align: center;
    color: #333333;
  }
  
  .form-group {
    width: 100%;
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    color: #555555;
    font-weight: bold;
  }
  
  .form-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .error-message {
    color: #072A44;
    margin-bottom: 15px;
    text-align: center;
  }
  
  .login-button {
    width: 100%;
    padding: 10px;
    background-color: #072A44;
    border: none;
    border-radius: 4px;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 10px; /* Espacio entre los campos y el botón */
  }
  
  .login-button:hover {
    background-color: #000;
  }
  